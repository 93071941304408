import * as React from "react";
import { Floatbox } from "../components/next/floatbox";
import "../components/next/layout.css";
import { Header, HeaderLeft, HeaderLogo, HeaderLogoImage, HeaderMenu, IconLink, NavigationItem, SocialIcon, SocialLinks } from "../components/next/header";
import skywardLogo from "../images/next/Logo.png";
import linkedIn from "../images/next/LinkedIn.png";
import pathOfAgesImage from "../images/next/content01.png";
import subscribeBackground from "../images/next/BG.png";
import { H1, HorizontalLine, Italic, Paragraph } from "../components/next/typography";
import { Aside, Background, CallToAction, Game, Section, Link } from "../components/next/content";
import { Footer, FooterItem, FooterLink, FooterLinkNative, FooterSection } from "../components/next/footer";
import { Claims, Claim, Newsletter, NewsletterForm, NewsletterLabel, NewsletterEmail, NewsletterSubmit, NewsletterSignup } from "../components/next/newsletter";
import { SEO } from "../components/seo";

const IndexPage = () => {
    return (<>
        <Floatbox id="header">
            <Header>
                <HeaderLogo>
                    <IconLink to="/" target="_blank">
                        <HeaderLogoImage src={skywardLogo} alt="Skyward Assembly Logo" />
                    </IconLink>
                </HeaderLogo>
                <HeaderMenu>
                    <HeaderLeft>
                        <NavigationItem>
                            <Link className="active" to="/">Games</Link>
                        </NavigationItem>
                        <NavigationItem>
                            <Link to="/about">Team</Link>
                        </NavigationItem>
                    </HeaderLeft>
                </HeaderMenu>
                <SocialLinks>
                    <IconLink to="https://www.linkedin.com/company/skyward-assembly/">
                        <SocialIcon src={linkedIn} alt="LinkedIn logo" />
                    </IconLink>
                </SocialLinks>
            </Header>
        </Floatbox>
        <Section>
            <Game>
                <Aside>
                    <H1>Path of Ages</H1>
                    <HorizontalLine />
                    <Paragraph><Italic>Path of Ages is a City Builder game where your goal is to push back the sands to rebuild society and lead your tribe to prosperity.</Italic></Paragraph>
                    <Paragraph><Italic>Everything you do will shape the world around you as you play. The environment will present unique challenges supported by deep simulation systems like water and sand. The key to sucess is managing your limited supply of water all while fulfilling the needs and desires of your people.</Italic></Paragraph>
                    <Paragraph><Italic>Researching or discovering ancient technology buried in the sands will expand your options and allow you to design interlocking production chains to fuel expansion and progress.</Italic></Paragraph>
                    <Paragraph><Italic>Crafting your leader persona from a set of ever expanding traits and perks will allow you to develop your personal playstyle.</Italic></Paragraph>
                    <Paragraph><Italic>You will meet unique political factions in each playthough that can either help or hinder your cause. They each come with their own needs and wants and it will be up to you to cater to them. Gaining their trust will present you with new technology, lore and unique gameplay paths. Neglecting them may lead to unrest.</Italic></Paragraph>
                    <Paragraph><Italic>Exploring the world over mutliple playthroughs will steadily expand the world map. Different biomes present you with their own challenges and their own payoffs, each of them yielding potential for new discoveries. Emerging story paths unfold to reveal more about your world, holdig the potential for unlocking new and exciting gameplay options for all parts of the game.</Italic></Paragraph>
                    <HorizontalLine />
                    <Paragraph>Path of Ages is built for Desktop Computers and Consoles, release date to be announced.</Paragraph>
                    <CallToAction href="mailto:studio@skywardassembly.com?subject=Pitch Request">Request a Pitch</CallToAction>
                </Aside>
                <Background style={{ backgroundImage: `url(${pathOfAgesImage})` }} />
            </Game>
        </Section>
        <Section>
            <Newsletter style={{ backgroundImage: `url(${subscribeBackground})` }}>
                <Claims>
                    <Claim>{'#procedural'}</Claim>
                    <Claim>{'#replayable'}</Claim>
                    <Claim>{'#worldbuilding'}</Claim>
                </Claims>
                <NewsletterForm target="_blank" action="https://skywardassembly.us17.list-manage.com/subscribe/post" id="mailform">
                    <NewsletterLabel htmlFor="MERGE0">Stay up to date with our newsletter</NewsletterLabel>
                    <input type="hidden" name="u" value="6c88f810f7fa0850eb9bcab10" />
                    <input type="hidden" name="id" value="4848dc1c84" />
                    <NewsletterSignup>
                        <NewsletterEmail type="email" autoComplete="email" name="MERGE0" id="MERGE0" placeholder="Enter your email address" />
                        <NewsletterSubmit type="submit" value="Subscribe" id="button_mail" />
                    </NewsletterSignup>
                </NewsletterForm>
            </Newsletter>
        </Section>
        <Section>
            <Footer>
                <FooterSection>
                    <FooterItem>
                        <FooterLink to="/imprint">IMPRINT</FooterLink>
                    </FooterItem>
                    <FooterItem>
                        <FooterLink to="/privacy-policy">PRIVACY POLICY</FooterLink>
                    </FooterItem>
                    <FooterItem>
                        <FooterLinkNative href="/Presskit.zip" target="_blank">PRESSKIT</FooterLinkNative>
                    </FooterItem>
                </FooterSection>
            </Footer>
        </Section>
    </>);
};

export default IndexPage;
export const Head = () => {
    console.log('head');
    return (<SEO />);
};
